import { Dispatch } from 'redux'
import { State } from 'state/store'
import { robotDataAnalysisExtractedImagesActions } from './actions'
import { Confirmed, QueryConditions, ExecutionDate, ModelGroup } from './types'
import { RobotDataAnalysisExtractedImagesApi } from './apis'
import { HttpsCallableResult } from 'firebase/functions'
import { getDocs, query, where } from 'firebase/firestore'
import { getTrainedModelGroupQueriesCollection } from 'state/firebase'
import { isUndefined } from 'utils/typeguard'
import { fireStoreTypeGuard as fireStoreTypeGuardForModelGroupQueryDocument } from 'utils/fireStore/modelGroupQuery'
import { convertISO8601 } from 'state/utils/converts'
import { TransformedRobotExecutionData } from 'state/utils/types'

const algorithmTypes = ['ObjectRecognition', 'CompartmentRecognition']

/**
 * selfConfirmed: ログインユーザーが確認済み
 * otherConfirmed: ログインユーザーは未確認かつ、他のユーザーが確認済み
 * unConfirmed: 未確認
 */
const createConfirmed = (
  checked: {
    ['account-group-id']: string
    ['account-id-list']: string[]
  }[],
  accountGroupId: string,
  accountId: string
): Confirmed => {
  const accountGroup = checked.find(
    (a) => a['account-group-id'] === accountGroupId
  )
  return accountGroup && accountGroup['account-id-list'].length >= 1
    ? accountGroup?.['account-id-list'].includes(accountId)
      ? 'selfConfirmed'
      : 'otherConfirmed'
    : 'unConfirmed'
}

export const robotDataAnalysisExtractedImagesOperations = {
  /** リストを取得する */
  getExecutionDataList:
    (executionData: QueryConditions) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(robotDataAnalysisExtractedImagesActions.setInProgress(true))
        const userGroupId: string =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        let executedAt: ExecutionDate | undefined

        if (executionData.executionDateFrom) {
          executedAt = {
            from: convertISO8601(executionData.executionDateFrom),
          }
        }
        if (executionData.executionDateTo) {
          executedAt = {
            ...executedAt,
            to: convertISO8601(executionData.executionDateTo, '23:59:59'),
          }
        }

        const params = {
          'execution-results': executionData.executionStatus,
          'executed-at': executedAt,
          'execution-id-list': executionData.executionIdList,
          'robot-id-list': executionData.robotIdList,
          'model-group-id-list': executionData.modelGroupIdList,
          'user-group-id-list': [userGroupId],
          confirmed: executionData.confirmed,
          extended: {
            'object-recognition': {
              'has-result': executionData.objectRecognitionResults,
            },
          },
        }

        const res =
          (await RobotDataAnalysisExtractedImagesApi.getExecutionDataList(
            params
          )) as HttpsCallableResult<{
            status: string
            result: TransformedRobotExecutionData[]
          }>

        // TODO: 現仕様では、対象物認識と区画認識のみであるため ObjectRecognition と CompartmentRecognition の algorithmId を取得
        const algorithmIds = getState()
          .app.domainData.algorithms.filter(
            (algorithm) =>
              algorithm.algorithmPurpose === 'ObjectRecognition' ||
              algorithm.algorithmPurpose === 'CompartmentRecognition'
          )
          .map((algorithm) => algorithm.algorithmId)

        const trainedModelGroupDocs = await getDocs(
          query(
            getTrainedModelGroupQueriesCollection(userGroupId),
            where('algorithm-id', 'in', algorithmIds)
          )
        )

        // getExecutionDataList() では modelGroupId だけの取得となり、表示する際に name が必要なため ModelGroup[] を全件取得
        const mGroups: ModelGroup[] = trainedModelGroupDocs.docs
          .map((doc) => {
            const data = doc.data()
            if (!fireStoreTypeGuardForModelGroupQueryDocument(data)) {
              return undefined
            }

            return {
              modelGroupId: data['trained-model-group-id'],
              modelGroupName: data['trained-model-group-name'],
            }
          })
          .filter((data) => !isUndefined(data)) as ModelGroup[]

        const accountId: string =
          getState().app.domainData.authedUser.auth.customClaims.accountId
        const accountGroupId: string =
          getState().app.domainData.authedUser.auth.customClaims.accountGroupId

        const result = res.data.result.map((item) => ({
          confirmed: createConfirmed(item.checked, accountGroupId, accountId),
          trainingData: {
            id: item['training-data-id'],
            name: item['file-name'],
          },
          executionStatus: item['execution-result'],
          executedAt: item['executed-at'],
          modelGroups: mGroups.filter((mg) =>
            item['robot-execution-data'].some(
              (reData) =>
                reData['trained-model']['trained-model-group-id'] ===
                mg.modelGroupId
            )
          ),
          executionId: item['execution-id'],
          robotId: item['robot-id'],
          isSupportedAlgorithm: item['robot-execution-data']?.[0]?.[
            'algorithm-purpose'
          ]
            ? algorithmTypes.includes(
                item['robot-execution-data'][0]['algorithm-purpose']
              )
            : false,
        }))

        const condition =
          getState().pages.robotDataAnalysisExtractedImagesState.domainData
            .robotDataAnalysisExtractedImagesDisplayCondition

        dispatch(robotDataAnalysisExtractedImagesActions.setList(result))
        dispatch(
          robotDataAnalysisExtractedImagesActions.setListDisplayCondition({
            ...condition,
            totalCount: result.length,
          })
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(robotDataAnalysisExtractedImagesActions.setInProgress(false))
      }
    },
}
