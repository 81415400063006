import { Dispatch } from 'redux'
import { State } from 'state/store'

import { robotDataAnalysisExtractedImageDetailActions } from './actions'
import { RobotDataAnalysisExtractedImageDetailApi } from './apis'
import {
  getRobotExecutionDataQueryCollectionRef,
  getTrainingDataCollection,
  getTrainedModelQueriesCollection,
} from 'state/firebase'
import { getDocs, query, where, getDoc, doc } from 'firebase/firestore'
import {
  InferenceResult,
  InferenceResultResponse,
  RecognizedData,
} from './types'
import { fireStoreTypeGuard as fireStoreTypeGuardForTrainingDataDocument } from 'utils/fireStore/trainingData'
import { fireStoreTypeGuard as fireStoreTypeGuardForModelQueryDocument } from 'utils/fireStore/modelQuery'
import { GetObjectApi } from '../datasetDetail/apis'
import { saveAs } from 'file-saver'

/**
 * selfConfirmed: ログインユーザーが確認済み
 * otherConfirmed: ログインユーザーは未確認かつ、他のユーザーが確認済み
 * unConfirmed: 未確認
 */
const createConfirmed = (
  checked: {
    [x: string]: {
      ['account-id-list']: string[]
    }
  },
  accountGroupId: string,
  accountId: string
): boolean => {
  const checkedList = checked?.[accountGroupId]?.['account-id-list'] ?? []

  return checkedList.includes(accountId)
}

const getOriginalUrls = async (
  userGroupId: string,
  trainingDataId: string
): Promise<string | undefined> => {
  const trainingData = (
    await getDoc(doc(getTrainingDataCollection(userGroupId), trainingDataId))
  ).data()

  if (!fireStoreTypeGuardForTrainingDataDocument(trainingData)) {
    return
  }

  const docFileName = trainingData?.['file-name']

  const originalUrls =
    await RobotDataAnalysisExtractedImageDetailApi.getSignedUrls(
      [
        {
          id: trainingDataId,
          fileName: docFileName,
        },
      ],
      'read',
      'original'
    )

  return originalUrls[trainingDataId]
}

export const robotDataAnalysisExtractedImageDetailOperations = {
  /** リストを取得する */
  getQueryDataList:
    (trainingDataId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(
          robotDataAnalysisExtractedImageDetailActions.setInProgress(true)
        )
        const userGroupId: string =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        const trainingData = (
          await getDoc(
            doc(getTrainingDataCollection(userGroupId), trainingDataId)
          )
        ).data()

        if (!trainingData) {
          return
        }

        const robotExecutionDataQuery = await getDocs(
          query(
            getRobotExecutionDataQueryCollectionRef(userGroupId),
            where('training-data-id', '==', trainingDataId)
          )
        )

        const recognizedData: (RecognizedData | undefined)[] =
          await Promise.all(
            robotExecutionDataQuery.docs.map(async (item) => {
              const robotExecutionData = item.data()
              const algorithm = Object.keys(
                robotExecutionData['extended']
              ).find(
                (k) =>
                  k === 'ObjectRecognition' || k === 'CompartmentRecognition'
              )

              if (!algorithm) {
                dispatch(
                  robotDataAnalysisExtractedImageDetailActions.setToastInfo({
                    type: 'error',
                    title: '非対応のアルゴリズムです',
                    targets: [],
                  })
                )
                return
              }

              const trainedModelData = (
                await getDoc(
                  doc(
                    getTrainedModelQueriesCollection(
                      robotExecutionData['extended'][algorithm][
                        'trained-model'
                      ]['user-group-id']
                    ),
                    robotExecutionData['extended'][algorithm]['trained-model'][
                      'trained-model-id'
                    ]
                  )
                )
              ).data()

              if (
                !fireStoreTypeGuardForModelQueryDocument(trainedModelData) ||
                !trainedModelData
              ) {
                return undefined
              }

              return {
                executionDataId: robotExecutionData['robot-execution-data-id'],
                model: {
                  trainedModelGroupId:
                    robotExecutionData['extended'][algorithm]['trained-model'][
                      'trained-model-group-id'
                    ],
                  trainedModelName: trainedModelData['trained-model-name'],
                  trainedModelVersion:
                    robotExecutionData['extended'][algorithm]['trained-model'][
                      'trained-model-group-version'
                    ]['display-name'],
                  trainedModelId:
                    robotExecutionData['extended'][algorithm]['trained-model'][
                      'trained-model-id'
                    ],
                  userGroupId:
                    robotExecutionData['extended'][algorithm]['trained-model'][
                      'user-group-id'
                    ],
                },
                labels: Object.keys(
                  robotExecutionData['extended'][algorithm]['inference-result'][
                    'recognized'
                  ]['labels']
                ).map((key: string) => {
                  return {
                    id: key,
                    score:
                      robotExecutionData['extended'][algorithm][
                        'inference-result'
                      ]['recognized']['labels'][key]['score-list'][0],
                  }
                }),
              } as RecognizedData
            })
          )

        const originalUrl = await getOriginalUrls(userGroupId, trainingDataId)

        const accountId: string =
          getState().app.domainData.authedUser.auth.customClaims.accountId
        const accountGroupId: string =
          getState().app.domainData.authedUser.auth.customClaims.accountGroupId

        const filteredRecognizedData = recognizedData.filter(
          (data) => data !== undefined
        ) as RecognizedData[]

        dispatch(
          robotDataAnalysisExtractedImageDetailActions.setExecutionData({
            trainingDataFileName: trainingData['file-name'],
            trainingDataId: trainingDataId,
            confirmed: createConfirmed(
              robotExecutionDataQuery.docs[0].data()['checked'],
              accountGroupId,
              accountId
            ),
            executionStatus:
              robotExecutionDataQuery.docs[0].data()['execution-result'],
            executedAt: robotExecutionDataQuery.docs[0].data()['executed-at'],
            robotId: robotExecutionDataQuery.docs[0].data()['robot-id'],
            url: originalUrl ?? '',
            recognizedData: filteredRecognizedData,
          })
        )

        const inferenceResultDisplayCondition =
          getState().pages.robotDataAnalysisExtractedImageDetailState.domainData
            .inferenceResultDisplayCondition

        dispatch(
          robotDataAnalysisExtractedImageDetailActions.setInferenceResultDisplayCondition(
            {
              ...inferenceResultDisplayCondition,
              selectedIds: filteredRecognizedData.map(
                (recognizedData) => recognizedData.model.trainedModelId
              ),
            }
          )
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(
          robotDataAnalysisExtractedImageDetailActions.setInProgress(false)
        )
      }
    },
  /** 実行結果を取得する */
  getExecutionLog:
    (trainingDataId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const userGroupId: string =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        const response =
          await RobotDataAnalysisExtractedImageDetailApi.getExecutionLogAnalysis(
            {
              'training-data-id': trainingDataId,
              'user-group-id': userGroupId,
            }
          )

        const responseData = response.data as InferenceResultResponse

        if (responseData.status === 'Success' && responseData.result) {
          dispatch(
            robotDataAnalysisExtractedImageDetailActions.setInferenceResults(
              responseData.result.inferenceResults as InferenceResult[]
            )
          )
        }
      } catch (error) {
        console.error(error)
      }
    },
  downloadTrainingImage:
    (trainingDataUrl: string) =>
    async (dispatch: Dispatch): Promise<void> => {
      let fileName = ''
      try {
        dispatch(
          robotDataAnalysisExtractedImageDetailActions.setInProgress(true)
        )
        fileName = trainingDataUrl.slice(
          trainingDataUrl.lastIndexOf('/') + 1,
          trainingDataUrl.lastIndexOf('?') > 0
            ? trainingDataUrl.lastIndexOf('?')
            : undefined
        )
        const res = await GetObjectApi.get(trainingDataUrl)
        const blob = res.data
        saveAs(blob, fileName)
      } catch (e) {
        dispatch(
          robotDataAnalysisExtractedImageDetailActions.setToastInfo({
            type: 'error',
            title: 'ダウンロードに失敗しました',
            targets: [fileName],
          })
        )
      } finally {
        dispatch(
          robotDataAnalysisExtractedImageDetailActions.setInProgress(false)
        )
      }
    },
  confirm:
    (trainingDataId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(
          robotDataAnalysisExtractedImageDetailActions.setInProgress(true)
        )
        const executionData =
          getState().pages.robotDataAnalysisExtractedImageDetailState.domainData
            .executionData

        if (!executionData) return

        const userGroupId: string =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        await RobotDataAnalysisExtractedImageDetailApi.confirm({
          'training-data-id': trainingDataId,
          'user-group-id': userGroupId,
        })

        dispatch(
          robotDataAnalysisExtractedImageDetailActions.setExecutionData({
            ...executionData,
            confirmed: !executionData.confirmed,
          })
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(
          robotDataAnalysisExtractedImageDetailActions.setInProgress(false)
        )
      }
    },
}
